import React, { useContext, useEffect, useState } from "react";
import backButton from "../components/themeimg/assets/back.png";
import { useNavigate } from "react-router-dom";
import * as BsIcons from "react-icons/bs";
import * as FaIcons from "react-icons/fa";
import ArrowIcon from "./section/ArrowIcon";
import LoaderComponent from './Loader';
import { Context } from "../Context/Context";
import { txt } from "./text";


function Gallery() {
  const navigate = useNavigate();
  const lang = localStorage.getItem("appLang")
    ? localStorage.getItem("appLang")
    : "en";
  const [isVisible, setIsVisible] = useState(-1);
  const [imgNumber, setImgNumber] = useState(0)
  const [loader , setLoader] = useState(true)
  // const {handlePageLoader} = useContext(Context)
  const [data, setData] = useState(null);
  // const [mainImg,setMainImg] = useState("https://sandboxapisv1.bngrenew.com/apn/r/html/" + data?.imageUrls[0])
  const [mainImg, setMainImg] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const {pageLoader , handlePageLoader, changeErrorState} = useContext(Context)
  const initialImageIndex = parseInt(localStorage.getItem("lastViewedImageIndex"), 10) || 0;
  const [currentImageIndex, setCurrentImageIndex] = useState(initialImageIndex);
  const header = sessionStorage.getItem("header")

  console.log("mainIng",mainImg)


  useEffect(() => {
    const url = `https://sandboxapisv1.bngrenew.com//ongoing_inwimo//r//html//gallery.php?lang=en`;
    const baseImageUrl = `https://sandboxapisv1.bngrenew.com//ongoing_inwimo//r//html/`

    handlePageLoader(true)

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        console.log(data,"data--")
        console.log('first',"https://sandboxapisv1.bngrenew.com//ongoing_inwimo//r//html/" + data?.imageUrls[0].url)
        const lastViewedImageIndex = localStorage.getItem("lastViewedImageIndex");
        setMainImg(
          "https://sandboxapisv1.bngrenew.com//ongoing_inwimo//r//html/" +
            (data?.imageUrls[lastViewedImageIndex]?.url || data?.imageUrls[0]?.url)
        );
        // handlePageLoader(false);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
        // Handle the error appropriately
      });
  }, []);


//   console.log("data --------> ", data);


useEffect(() => {
  if(!localStorage.getItem("lastViewedImageIndex")){
    localStorage.setItem("lastViewedImageIndex", 0);
  }
  if (imgNumber >= data?.imageUrls.length) {
    handlePageLoader(false);
  }
}, [imgNumber, data]);



// Imagedata.imageUrls.map(value => {
//     console.log(value.url);
// });

const handleImageClick = (url, index) => {
  if (index !== currentImageIndex) {
    setIsLoading(true);
  } 
  setMainImg("https://sandboxapisv1.bngrenew.com//ongoing_inwimo//r//html/" + url);
  setCurrentImageIndex(index);
  localStorage.setItem("lastViewedImageIndex", index);
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const handleImageLoad = () => {
  setImgNumber((value) => value + 1);
  setIsLoading(false); 
};

const handlePrevImage = () => {
  setIsLoading(true)
  setCurrentImageIndex(prevIndex => {
    const storedIndex = parseInt(localStorage.getItem("lastViewedImageIndex"), 10);
    const newIndex = storedIndex > 0 ? storedIndex - 1 : prevIndex;
    localStorage.setItem("lastViewedImageIndex", newIndex);
    return newIndex;
  });
};

const handleNextImage = () => {
  setIsLoading(true)
  setCurrentImageIndex(prevIndex => {
    // const newIndex = prevIndex < data?.imageUrls.length - 1 ? prevIndex + 1 : prevIndex;
    const storedIndex = parseInt(localStorage.getItem("lastViewedImageIndex"), 10);
    const newIndex =
      storedIndex < data?.imageUrls.length - 1 ? storedIndex + 1 : prevIndex;
    localStorage.setItem("lastViewedImageIndex", newIndex);
    return newIndex;
  });
};

useEffect(() => {
  if (data && data.imageUrls.length > 0) {
    setMainImg("https://sandboxapisv1.bngrenew.com//ongoing_inwimo//r//html/" + data.imageUrls[currentImageIndex].url);
  }
}, [currentImageIndex, data]);

const gotoHome = () => {
  navigate('/home','_self');
}
console.log("currentindex",currentImageIndex)


  return(
        <div className="h-screen max-w-[500px] mx-auto">
          <div className="h-20 flex bg-white">
            {/* <div className='border-2 border-black my-auto w-4 ml-4 h-4'></div> */}
            {/* <img
              className="w-6 my-auto ml-4"
              onClick={() => navigate(-1)}
              src={backButton}
            /> */}
            <div className="pt-6">
             <div className='back-arrow back-btn-quiz' onClick={() => gotoHome()}>
             <div className='back-arrow-button back-btn-align'>
                          <p  className="arrow left "></p>
                        </div>
             </div></div>
            <div className="my-auto mx-auto font-bold relative font-playfair right-4 text-[#0C0C0C] text-2xl">
            {txt.gallery[lang]}
            </div>
          </div>
          <div className="ml-2 mr-2 pt-4  text-black ">
            {pageLoader && <LoaderComponent />}
      <div className="w-[90%] mx-auto h-60 relative">
      {isLoading && <LoaderComponent />}
      <div className="bg-[#9D328D] h-8 w-8 rounded-full absolute left-4 top-1/2 transform -translate-y-1/2">
      <div
          className={`absolute left-3 top-1/2 transform -translate-y-1/2 cursor-pointer z-10 text-xl text-white ${
            currentImageIndex === 0 ? "pointer-events-none opacity-50" : ""
          }`}
          onClick={handlePrevImage}
        >&lt;</div></div>
      <img
                        className=" border-2 border-white rounded-lg h-full"
                        src={mainImg}
                        alt=""
                        onLoad={handleImageLoad}
                    />
                    <div className="bg-[#9D328D] h-8 w-8 rounded-full absolute right-4 top-1/2 transform -translate-y-1/2">
                    <div
          className={`absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer z-10 text-xl text-white ${
            currentImageIndex === data?.imageUrls.length - 1
              ? "pointer-events-none opacity-50"
              : ""
          }`}
          onClick={handleNextImage}
        >
          &gt;
        </div>

                    </div>
      </div>
    <div className="" style={{ display: 'flex', justifyContent: 'center', padding: "12px"}}>
        <div className="grid grid-cols-2 gap-x-4 gap-y-4">
            {data?.imageUrls.map((data,index) => (
                <div className="relative w-[11rem] h-[11rem]" key={data.url}>
                  
                    <img
                        onLoad={() => setImgNumber(value => value + 1)}
                        className="absolute border-2 border-black rounded-lg w-full h-full"
                        src={"https://sandboxapisv1.bngrenew.com//ongoing_inwimo//r//html/" + data.url}
                        alt=""
                        onClick={() => handleImageClick(data.url,index)}
                        loading="lazy"
                    />
                    
                </div>
            ))}
        </div>
    </div>
          </div>
        </div>
      )

}

export default Gallery;
